.register-container {
    display: flex;
    height: 100vh;
    background-color: #f5f5f5;
  }
  
  .register-left {
    flex: 1;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    padding: 2rem;
    background: linear-gradient(to right, #317924, #12c739);
    color: white;
  }
  
  .welcome-text {
    font-size: 3rem;
    font-weight: bold;
  }
  
  .info-text {
    font-size: 1.5rem;
  }
  
  .register-right {
    flex: 1;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 2rem;
    border-radius: 15px;
    box-shadow: 0 4px 20px rgba(0, 0, 0, 0.1);
  }
  
  .register-avatar {
    margin: 1rem;
    background-color: hwb(128 3% 23%);
  }
  
  .register-title {
    font-weight: bold;
  }
  
  .register-button {
    margin-top: 1rem;
    border-radius: 8px;
    background: rgb(61, 123, 145);
    transition: background 0.3s ease; /* Smooth transition for background */
}

.register-button:hover {
    background: linear-gradient(90deg, rgb(51, 118, 143) 35%, rgb(68, 190, 201) 100%); /* Adjusted colors for hover state */
}

  
  .link-text {
    text-decoration: none;
    color: #22a12c;
  }
  